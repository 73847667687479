import React, { useState, useEffect } from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Client } from '@microsoft/microsoft-graph-client';
import { configApiRef, microsoftAuthApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import TextField from '@material-ui/core/TextField';
import { Grid, Button, Typography } from '@material-ui/core';

export const RoleAssignmentPage = () => {
  const microsoftAuth = useApi(microsoftAuthApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
 
  interface Role {
    id: string;
    displayName: string;
  }

  interface Group {
    id: string;
    displayName: string;
  }


  interface User {
    id: string;
    displayName: string;
    mail: string;
    jobTitle: string;
    officeLocation: string;
  }

  const [roles, setRoles] = useState<Role[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [userId, setUserId] = useState('');
  const [userInfo, setUserInfo] = useState<User | null>(null);

  useEffect(() => {
    const fetchRolesAndGroups = async () => {
      const token = await microsoftAuth.getAccessToken(['https://graph.microsoft.com/.default']);
      const client = Client.initWithMiddleware({
        authProvider: {
          getAccessToken: async () => token,
        },
      });

      const rolesResponse = await client.api('/directoryRoles').get();
      setRoles(rolesResponse.value);

      const groupsResponse = await client.api('/groups').get();
      setGroups(groupsResponse.value);
    };

    const getUser = async () => {
      const user = await identityApi.getProfileInfo();
      setUserId(user.email || user.displayName || '');

      const token = await microsoftAuth.getAccessToken(['https://graph.microsoft.com/.default']);
      const client = Client.initWithMiddleware({
        authProvider: {
          getAccessToken: async () => token,
        },
      });

      const userResponse = await client.api('/me').get();
      setUserInfo(userResponse);
    };

    fetchRolesAndGroups();
    getUser();
  }, [microsoftAuth, identityApi]);

  const isValidGuid = (guid: string) => {
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(guid);
  };

  const assignRole = async () => {
    const resourceId = process.env.REACT_APP_AZURE_CLIENT_ID;

    if (!isValidGuid(resourceId || '') || !isValidGuid(selectedRole)) {
      alert('Invalid GUID format for resourceId or appRoleId');
      return;
    }

    if (configApi.getString('auth.environment') === 'development') {
      // Simulated role assignment for local development
      console.log('Simulated role assignment:', {
        principalId: userId,
        resourceId: resourceId,
        appRoleId: selectedRole,
      });
      alert('Simulated role assigned successfully');
    } else {
      const token = await microsoftAuth.getAccessToken(['https://graph.microsoft.com/.default']);
      const client = Client.initWithMiddleware({
        authProvider: {
          getAccessToken: async () => token,
        },
      });

      await client.api(`/users/${userId}/appRoleAssignments`).post({
        principalId: userId,
        resourceId: resourceId,
        appRoleId: selectedRole,
      });

      alert('Role assigned successfully');
    }
  };

  return (
    <Page themeId="tool">
      <Header title="Role Assignment" />
      <Content>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2>Available Roles</h2>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label="Select Role"
              value={selectedRole}
              onChange={e => setSelectedRole((e.target as HTMLInputElement).value)}
              fullWidth
            >
              {roles.map(role => (
                <MenuItem key={role.id} value={role.id}>
                  {role.displayName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <h2>Available Groups</h2>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              label="Select Group"
              value={selectedGroup}
              onChange={e => setSelectedGroup((e.target as HTMLInputElement).value)}
              fullWidth
            >
              {groups.map(group => (
                <MenuItem key={group.id} value={group.id}>
                  {group.displayName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="User ID"
              value={userId}
              onChange={e => setUserId(e.target.value)}
              fullWidth
            />
            {userInfo && (
              <div>
                <Typography variant="body1">Name: {userInfo.displayName}</Typography>
                <Typography variant="body1">Email: {userInfo.mail}</Typography>
                <Typography variant="body1">Job Title: {userInfo.jobTitle}</Typography>
                <Typography variant="body1">Office Location: {userInfo.officeLocation}</Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={assignRole}>
              Assign Role
            </Button>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};