import React, { useState, useEffect } from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Client } from '@microsoft/microsoft-graph-client';
import { configApiRef, microsoftAuthApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import TextField from '@material-ui/core/TextField';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows,
  },
  select: {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export const TribeSelector = () => {
  const classes = useStyles();
  const [tribes, setTribes] = useState([]);
  const [selectedTribe, setSelectedTribe] = useState('');
  const [cells, setCells] = useState([]);

  const microsoftAuth = useApi(microsoftAuthApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);

  const [userId, setUserId] = useState('');
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const privateTk = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1jN2wzSXo5M2c3dXdnTmVFbW13X1dZR1BrbyJ9.eyJhdWQiOiJhM2U4Nzc0Ni1iYzk5LTQ2NmYtYjRhZi02NjM3ODc4MzZmM2YiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZjViMGQ2ODItMTQ5Ny00ZGIwLTkwMTktNjYwMDM1NTU0ZTcyL3YyLjAiLCJpYXQiOjE3MjgyNzkyNTIsIm5iZiI6MTcyODI3OTI1MiwiZXhwIjoxNzI4MjgzMTUyLCJhaW8iOiJBWFFBaS84WUFBQUFOL0hTZUNVZnJBa0FraGxxU0c3NzJXdzBORzJNTGRySEVZRkQ3MGFvTk1TV3Q0dEM1ODl2QTZzL3duZzZEcVNTVHMyNTREMHYwQ2Nyd2JZalRZZDlBTFdWdkpIR2NUUFN5U2srRE9VNlptOGt2Z3N4aGpma2Exa0dnRmtKakN4d2dHUk5OZVczZjBsd25UREdTK1BOd2c9PSIsIm5hbWUiOiJBSVpBR0EgQ09CQSBBTkRSRVMgSVNBSUFTIiwibm9uY2UiOiIwMTkyNjU3ZC0wYjZmLTdkMDgtOTc2Yi05YjdmN2VkMzZjYWIiLCJvaWQiOiI2YjA0MzJlZC03NDg3LTRmNWUtYjU5Ni1hOWEwZThmZjYwMzkiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhYWl6YWdhY0BwaWNoaW5jaGEuY29tIiwicmgiOiIwLkFRb0FndGF3OVpjVXNFMlFHV1lBTlZWT2NrWjM2S09adkc5R3RLOW1ONGVEYno4S0FJYy4iLCJyb2xlcyI6WyJVc3VhcmlvIl0sInN1YiI6Ikp1S2RPQ2NzbURibUhVM2tZSUtZb1RYZ3ZzSmlkd25vdUxTZUEtN3BzNnciLCJ0aWQiOiJmNWIwZDY4Mi0xNDk3LTRkYjAtOTAxOS02NjAwMzU1NTRlNzIiLCJ1dGkiOiJobWE0VVpJT0xrR3pFUk91My1IZEFBIiwidmVyIjoiMi4wIn0.gGe7nCYASu4W4cNXQnaPRYyK2sRMhU0B86p_ZfkpydnCe90YJuAstc9hQXEas14VHHWo8u7uCVfxCF3rgvajgl9xAc5pNiDyivAmipjLqaqFSf6tiLWxzOZ1SWZwv9M94yPq3ZvZNG2_4IFECSK0E652Hfiq5Djjwg6u1bfohlFsOVIWlDED9i38hNkCEvt7BWUmcZeUQQb4PTg80THEhCeGknFHr71dJQSMf7t5XX9zRFK4kUO6Lrgj_5shyy5dImhdiVdcsYUdC3jlMBsAcwNVH5FJZkWzlsJRlHrIFQUXU6N90qOoPneML8ZANu7U40cjEyUxPFnDmmkr_w1VSA';

  const [tokenAD, setTokenAD] = useState(null);

 interface User {
    id: string;
    displayName: string;
    mail: string;
    jobTitle: string;
    officeLocation: string;
  }

  useEffect(() => {

     const getUser = async () => {
       console.log('getting token...')
       
         console.log('getUser...');
       const user = await identityApi.getProfileInfo();
       console.log('user:', user);
       setUserId(user.email || user.displayName || '');

       const token = await microsoftAuth.getAccessToken(['https://graph.microsoft.com/.default']);
       console.log('token:', token);
       const client = Client.initWithMiddleware({
         authProvider: {
           getAccessToken: async () => token,
         },
       });
       console.log('client:', client);

       const userResponse = await client.api('/me').get();
       console.log('userResponse:', userResponse);
       setUserInfo(userResponse);

     };

     getUser();
     fetchTribes(privateTk);

  }, [microsoftAuth, identityApi]);

  const fetchTribes = async (token) => {
      try {
        const response = await fetch('https://tribu-ti-framework-prod-eca2excph3hndubp.z01.azurefd.net/optimus-generator/staffing/tribes/', {
          headers: {
            'Authorization': `${token}`
          }
        });
        const data = await response.json();
        setTribes(data.data.tribu);
      } catch (error) {
        console.error('Error fetching tribes:', error);
      }
    };

    const fetchCells = async (tribeId, token) => {
      try {
        const response = await fetch(`https://tribu-ti-framework-prod-eca2excph3hndubp.z01.azurefd.net/optimus-generator/staffing/tribes/${tribeId}/cell/`, {
          headers: {
            'Authorization': `${token}`
          }
        });
        const data = await response.json();
        setCells(data.data.celltribu);
      } catch (error) {
        console.error('Error fetching cells:', error);
      }
    };

    const handleTribeChange = async (event) => {
      const tribeId = event.target.value;
      setSelectedTribe(tribeId);
      const token = await microsoftAuth.getAccessToken(['https://graph.microsoft.com/.default']);
      fetchCells(tribeId, privateTk);
    };

  return (
    <Page themeId="home">
      <Header title="Ejercicio de Backstage" subtitle="Seleccionar una tribu y celula" />
      <Content>
        <div className={classes.formContainer}>
          <Grid item xs={12} sm={6}>
              <TextField
                label="User ID"
                value={userId}
                onChange={e => setUserId(e.target.value)}
                fullWidth
                disabled
              />
              {userInfo && (
                <div>
                  <Typography variant="body1">Name: {userInfo.displayName}</Typography>
                  <Typography variant="body1">Email: {userInfo.mail}</Typography>
                  <Typography variant="body1">Job Title: {userInfo.jobTitle}</Typography>
                  <Typography variant="body1">Office Location: {userInfo.officeLocation}</Typography>
                </div>
              )}
            </Grid>

          <div>
            <select onChange={handleTribeChange} value={selectedTribe} className={classes.select}>
              <option value="">Seleccione una tribu</option>
              {tribes.map((tribe) => (
                <option key={tribe.id} value={tribe.id}>
                  {tribe.tribuName}
                </option>
              ))}
            </select>
          </div>
          {selectedTribe && (
            <div>
              <select className={classes.select}>
                <option value="">Seleccione una celula</option>
                {cells.map((cell) => (
                  <option key={cell.id} value={cell.id}>
                    {cell.celulaNameProduct}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </Content>
    </Page>
  );
};

export default TribeSelector;
