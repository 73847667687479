import { createPlugin, createRoutableExtension, createRouteRef } from '@backstage/core-plugin-api';
import { RoleAssignmentPage } from './components/RoleAssignmentPage';

// Define the route reference
const rootRouteRef = createRouteRef({
  id: 'role-assignment',
});

// Create the plugin
export const roleAssignmentPlugin = createPlugin({
  id: 'role-assignment',
  routes: {
    root: rootRouteRef,
  },
  register({ router }) {
    router.addRoute(this.routes.root, RoleAssignmentPage);
  },
});

// Provide the routable extension
export const RoleAssignmentPageExtension = roleAssignmentPlugin.provide(
  createRoutableExtension({
    name: 'RoleAssignmentPage',
    component: () =>
      import('./components/RoleAssignmentPage').then(m => m.RoleAssignmentPage),
    mountPoint: rootRouteRef,
  }),
);  