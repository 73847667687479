import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useApi, alertApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { accessManagementApiRef } from '../plugins/access-management/api';

const rolesList = ['Administrador', 'Desarrollador', 'QA', 'Arquitecto', 'Lectura'];
const allowedEmail = 'mdaldaz@pichincha.com'; // Reemplaza con el correo electrónico permitido

export const AccessManagementPage = () => {
  const [userId, setUserId] = useState('');
  const [roles, setRoles] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);
  const alertApi = useApi(alertApiRef);
  const identityApi = useApi(identityApiRef);
  const accessManagementApi = useApi(accessManagementApiRef);

  useEffect(() => {
    const fetchUserId = async () => {
      const identity = await identityApi.getProfileInfo();
      setUserId(identity.email); // Assuming the user ID is the email
      setIsAllowed(identity.email === allowedEmail);
    };
    fetchUserId();
  }, [identityApi]);

  const handleFetchRoles = async () => {
    try {
      const userRoles = await accessManagementApi.getRoles(userId);
      setRoles(userRoles);
    } catch (error) {
      alertApi.post({ message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleUpdateRoles = async () => {
    try {
      await accessManagementApi.updateRoles(userId, roles);
      alertApi.post({ message: 'Roles updated successfully', severity: 'success' });
    } catch (error) {
      alertApi.post({ message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleRoleChange = (role: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRoles = event.target.checked
      ? [...roles, role]
      : roles.filter(r => r !== role);
    setRoles(newRoles);
  };

  if (!isAllowed) {
    return <Typography variant="h6">No tienes permiso para ver esta página.</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">Gestión de Accesos</Typography>
      <TextField label="User ID" value={userId} onChange={e => setUserId(e.target.value)} />
      <Button variant="contained" color="primary" onClick={handleFetchRoles}>Fetch Roles</Button>
      {rolesList.map(role => (
        <FormControlLabel
          key={role}
          control={
            <Checkbox
              checked={roles.includes(role)}
              onChange={handleRoleChange(role)}
              name={role}
            />
          }
          label={role}
        />
      ))}
      <Button variant="contained" color="primary" onClick={handleUpdateRoles}>Update Roles</Button>
    </div>
  );
};