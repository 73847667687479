import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { rootRouteRef, ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { LightBox, ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage,AutoLogout,  } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes} from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightIcon from '@material-ui/icons/WbSunny';
import {   createTheme,
  genPageTheme,
  lightTheme,
  shapes } from '@backstage/theme';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/Home/HomePage';
import { ProxiedSignInPage } from '@backstage/core-components';
import { configApiRef, microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { TechDocsIndexPage, TechDocsReaderPage, techdocsPlugin } from '@backstage/plugin-techdocs';
import { EntityValidationPage } from '@backstage/plugin-entity-validation';
import { roleAssignmentPlugin } from './plugins/role-assignment'; // Ensure this path is correct
import { RoleAssignmentPage } from './plugins/role-assignment/components/RoleAssignmentPage';
import TribeSelector from './components/tribeSelector/TribeSelector';
import { AccessManagementPage } from './components/AccessManagementPage';
import { apis } from './api';

const palette= lightTheme.palette;

palette.background.paper = '#FFFFFF';
palette.primary.contrastText = '#fffbe5';
palette.primary.main = '#0f265c';
palette.secondary.contrastText = '#000000';
palette.navigation.background = '#f6f6f6';
palette.navigation.indicator= '#000000';
palette.navigation.color= '#0f265c';
palette.navigation.selectedColor= '#939eb8';
palette.navigation.submenu= {background: '#adadad'};
palette.secondary.contrastText = '#00FFFF';
palette.navigation.navItem= {hoverBackground : '#e7e9ee'};
palette.text.primary="#0f265c";
palette.infoBackground='#ffdd00'



const myTheme = createTheme({
  palette: palette,
  fontFamily: 'Arial',
  defaultPageTheme: 'home',
     
  pageTheme: {
    home:          genPageTheme({ colors: ['#0f265c'], shape: shapes.wave }),
    documentation: genPageTheme({ colors: ['#0f265c'], shape: shapes.round,     }),
    tool:          genPageTheme({ colors: ['#0f265c'], shape: shapes.round }),
    service:       genPageTheme({ colors: ['#0f265c'], shape: shapes.round,    }),
    website:       genPageTheme({ colors: ['#0f265c'], shape: shapes.round,    }),
    library:       genPageTheme({ colors: ['#0f265c'], shape: shapes.round,    }),
    other:         genPageTheme({ colors: ['#0f265c'], shape: shapes.round }),
    app:           genPageTheme({ colors: ['#0f265c'], shape: shapes.round }),
    apis:          genPageTheme({ colors: ['#0f265c'], shape: shapes.round }),
  },
});

const app = createApp({
  plugins: [
    roleAssignmentPlugin,
    // other plugins
  ],

  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
              
      if (configApi.getString('auth.environment') !== 'development') 
      {
        return (
          <SignInPage
          {...props} 
          provider={{
            id: 'microsoft',
            title: 'Azure Active Directory',
            message: 'Sign in using AD',
            apiRef: microsoftAuthApiRef,
          }}
          title="Select a sign-in method"
          align="center"
        />
        )
      }
      return (
            <SignInPage
            {...props}
            providers={['guest', 'custom']}
            title="Select a sign-in method"
            align="center"
          />
      );
    },
  },

  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(rootRouteRef, AccessManagementPage);
  },  
  themes: [{
    id: 'my-theme',
    title: 'My Custom Theme',
    variant: "light",
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={myTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  }]
  });

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
    
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    // <Route path="/newcomponent" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    
    
    
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/role-assignment"  element={<RoleAssignmentPage/>} />
    <Route path="/Access-Management" element={<AccessManagementPage />} />
    <Route path="/tribe-selector" element={<TribeSelector />} />
    <Route path="/close" />

  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AutoLogout
    
    idleTimeoutMinutes={10}
    useWorkerTimers={false}
    logoutIfDisconnected={true}
    />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
