import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export const accessManagementApiRef = createApiRef<AccessManagementApi>({
  id: 'plugin.access-management.service',
});

export interface AccessManagementApi {
  getRoles(userId: string): Promise<any>;
  updateRoles(userId: string, roles: any): Promise<void>;
}

export class AccessManagementApiClient implements AccessManagementApi {
  constructor(private readonly discoveryApi: DiscoveryApi, private readonly identityApi: IdentityApi) {}

  async getRoles(userId: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('access-management')}/roles/${userId}`;
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch roles: ${response.statusText}`);
    }

    return await response.json();
  }

  async updateRoles(userId: string, roles: any): Promise<void> {
    const url = `${await this.discoveryApi.getBaseUrl('access-management')}/roles/${userId}`;
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ roles }),
    });

    if (!response.ok) {
      throw new Error(`Failed to update roles: ${response.statusText}`);
    }
  }
}